import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import * as style from './style.module.css'

const Page = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { path, title } = frontmatter
  return (
    <Layout path={path} title={title}>
      <article
        className={style.articleBody}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($pagePath: String!) {
    markdownRemark(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default Page
